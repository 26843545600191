import React from 'react';
import Helmet from 'react-helmet';
import Slider from 'react-slick';
import {withRouteData} from "react-static";

const settings = {
  dots: false,
  arrows: false,
  pauseOnHover: false,
  fade: true,
  autoplay: true,
  infinite: true,
  speed: 1500,
  slidesToShow: 1,
  slidesToScroll: 1,
  swipe: false,
  swipeToSlide: false,
  className: 'slider',
};

export default withRouteData(({ slides, content }) => (<React.Fragment>
  <Helmet title={content ? content.title : 'Moore, Johnson, and Saraniti Law Firm P.A.'} />
  <Slider {...settings}>
    {(slides || []).map((slide, slideIndex) => (<div key={slideIndex}>
      <section>
        <section className="headingArea">
          <h3>{slide.header}</h3>
          <p>{slide.body}</p>
        </section>
        <video poster={slide.backgroundImage} id={`${slideIndex}SlideVideo`} playsInline autoPlay muted loop>
          {slide.backgroundVideos.map((video, videoIndex) => {
            return (<source key={videoIndex} src={video.source} type={video.type} />);
          })}
        </video>
      </section>
    </div>))}
  </Slider>
</React.Fragment>));
